import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import currentUpdate from '../assets/images/currentUpdate.png';

const ChaptersGroupStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  .currentUpdate {
    width: 139px;
    height: 30px;
    background: url(${currentUpdate});
    background-size: 139px;
    margin-bottom: 60px;
  }
`;

const ChapterListStyles = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 160px;
  .chapterCard {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h3 {
      color: var(--black);
      font-size: 18px;
      margin: 10px;
    }
    h4 {
      color: var(--black);
      font-size: 16px;
      margin: 0;
    }
    p {
      color: var(--grey);
      font-size: 16px;
      margin: 10px 0;
    }
    .chapterCover {
      width: 100%;
      background: white;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 24px;
    }
  }
`;

function SingleChapter({ chapter }) {
  return (
    <Link
      to={`/${chapter.book.slug.current}/${chapter.slug.current}`}
      className="chapterCard"
    >
      <div className="chapterCover">
        <Img fluid={chapter.mainImage.asset.fluid} alt={chapter.title} />
      </div>
      <h3>{chapter.title}</h3>
      <h4>《{chapter.book.name}》</h4>
      <p>{chapter.publishedAt}</p>
    </Link>
  );
}

export default function ChapterList({ chapters }) {
  return (
    <ChaptersGroupStyles>
      <div className="currentUpdate" />
      <ChapterListStyles>
        {chapters.map((chapter) => (
          <SingleChapter chapter={chapter} key={chapter.id} />
        ))}
      </ChapterListStyles>
    </ChaptersGroupStyles>
  );
}
