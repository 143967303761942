import React from 'react';
import { graphql } from 'gatsby';
import ChapterList from '../components/ChapterList';
import BookList from '../components/BookList';

export const query = graphql`
  query MyQuery {
    chapters: allSanityChapter(sort: { order: DESC, fields: publishedAt }) {
      nodes {
        title
        slug {
          current
        }
        id
        mainImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        publishedAt(formatString: "YYYY-MM-DD")
        book {
          id
          name
          slug {
            current
          }
        }
      }
    }
    books: allSanityBook {
      nodes {
        id
        name
        slug {
          current
        }
        cover {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;

export default function HomePage({ data }) {
  const chapters = data.chapters.nodes;
  const books = data.books.nodes;
  const booksNode = {
    books,
    chapters,
  };

  return (
    <div>
      <BookList booksNode={booksNode} />
      <ChapterList chapters={chapters} />
    </div>
  );
}
