import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import allBooksTitle from '../assets/images/myWorks.png';

const BooksGroupStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  .myBooks {
    width: 138px;
    height: 30px;
    background: url(${allBooksTitle});
    background-size: 139px;
    margin-bottom: 60px;
  }
`;

const BookListStyles = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  .bookCard {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h3 {
      color: var(--black);
      font-size: 18px;
      margin: 10px;
    }
    p {
      color: var(--grey);
      font-size: 16px;
      margin-bottom: 10px;
    }
    .bookCover {
      width: 100%;
      background: white;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 24px;
    }
  }
`;

function SingleBook({ bookNode }) {
  const chapters = bookNode.chaptersInBook;
  const { book } = bookNode;
  return (
    <Link to={`/${book.slug.current}`} className="bookCard">
      <div className="bookCover">
        <Img fluid={book.cover.asset.fluid} alt={book.name} />
      </div>
      <div className="bookInfo">
        <h3>{book.name}</h3>
        <p>连载中，已更新 {chapters.length} 话</p>
        <p>
          最近更新：{chapters.length > 0 ? chapters[0].publishedAt : '未有更新'}
        </p>
      </div>
    </Link>
  );
}

export default function BookList({ booksNode }) {
  const { chapters } = booksNode;
  return (
    <BooksGroupStyles>
      <div className="myBooks" />
      <BookListStyles>
        {booksNode.books.map((book) => {
          const chaptersInBook = chapters.filter(
            (chapter) => chapter.book.id === book.id
          );
          const bookNode = {
            book,
            chaptersInBook,
          };
          return <SingleBook bookNode={bookNode} key={book.id} />;
        })}
      </BookListStyles>
    </BooksGroupStyles>
  );
}
